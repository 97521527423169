function getEnv(key: string) {
  return process.env[key];
}

export const APP_CONFIG = {
  get url(): string {
    return getEnv("APP_URL") || "$APP_URL";
  },
  get name(): string {
    return getEnv("APP_NAME") || "$APP_NAME";
  },
  get title(): string {
    return getEnv("APP_TITLE") || "$APP_TITLE";
  },
  get description(): string {
    return getEnv("APP_DESCRIPTION") || "$APP_DESCRIPTION";
  },
  get domain(): string {
    return getEnv("APP_DOMAIN") || "$APP_DOMAIN";
  },

  contact: {
    email: {
      get label(): string {
        return getEnv("APP_EMAIL_LABEL") || "$APP_EMAIL_LABEL";
      },
      /**
       * The email address that will be used as the sender of the emails.
       * @string Name <email@example.com>
       */
      get from(): string {
        return getEnv("APP_EMAIL_FROM") || "$APP_EMAIL_FROM";
      },
      get contact(): string {
        return getEnv("APP_EMAIL_CONTACT") || "$APP_EMAIL_CONTACT";
      },
    },
    phone: {
      get label(): string {
        return getEnv("APP_PHONE_LABEL") || "$APP_PHONE_LABEL";
      },
      get prefix(): string {
        return getEnv("APP_PHONE_PREFIX") || "$APP_PHONE_PREFIX";
      },
      get number(): string {
        return getEnv("APP_PHONE_NUMBER") || "$APP_PHONE_NUMBER";
      },
    },
    whatsapp: {
      get label(): string {
        return getEnv("APP_WHATSAPP_LABEL") || "$APP_WHATSAPP_LABEL";
      },
      get prefix(): string {
        return getEnv("APP_WHATSAPP_PREFIX") || "$APP_WHATSAPP_PREFIX";
      },
      get number(): string {
        return getEnv("APP_WHATSAPP_NUMBER") || "$APP_WHATSAPP_NUMBER";
      },
    },
  },

  organization: {
    get name(): string {
      return getEnv("APP_ORG_NAME") || "$APP_ORG_NAME";
    },
    get address(): string {
      return getEnv("APP_ORG_ADDRESS") || "$APP_ORG_ADDRESS";
    },
  },

  maker: {
    get name(): string {
      return getEnv("APP_MAKER_NAME") || "$APP_MAKER_NAME";
    },
    get image(): string {
      return getEnv("APP_MAKER_IMAGE") || "$APP_MAKER_IMAGE";
    },
    get website(): string {
      return getEnv("APP_MAKER_WEBSITE") || "$APP_MAKER_WEBSITE";
    },
    get twitter(): string {
      return getEnv("APP_MAKER_TWITTER") || "$APP_MAKER_TWITTER";
    },
  },

  social: {
    linkedin: {
      get url(): string {
        return getEnv("APP_LINKEDIN_URL") || "$APP_LINKEDIN_URL";
      },
      get label(): string {
        return getEnv("APP_LINKEDIN_LABEL") || "$APP_LINKEDIN_LABEL";
      },
    },
    facebook: {
      get url(): string {
        return getEnv("APP_FACEBOOK_URL") || "$APP_FACEBOOK_URL";
      },
      get label(): string {
        return getEnv("APP_FACEBOOK_LABEL") || "$APP_FACEBOOK_LABEL";
      },
    },
    x: {
      facebook: {
        get url(): string {
          return getEnv("APP_X_URL") || "$APP_X_URL";
        },
        get label(): string {
          return getEnv("APP_X_LABEL") || "$APP_X_LABEL";
        },
      },
    },
    instagram: {
      facebook: {
        get url(): string {
          return getEnv("APP_INSTAGRAM_URL") || "$APP_INSTAGRAM_URL";
        },
        get label(): string {
          return getEnv("APP_INSTAGRAM_LABEL") || "$APP_INSTAGRAM_LABEL";
        },
      },
    },
  },
};

export const SiteConfig = {
  title: APP_CONFIG.title,
  description: APP_CONFIG.description,
  prodUrl: APP_CONFIG.url,
  domain: APP_CONFIG.domain,
  appIcon: "/icon.png",
  company: {
    name: APP_CONFIG.organization.name,
    address: APP_CONFIG.organization.address,
  },
  brand: {
    primary: "#007291",
  },
  email: {
    from: APP_CONFIG.contact.email.from,
    contact: APP_CONFIG.contact.email.contact,
  },
  auth: {
    password: false as boolean,
  },
  maker: {
    image: APP_CONFIG.maker.image,
    website: APP_CONFIG.maker.website,
    twitter: APP_CONFIG.maker.twitter,
    name: APP_CONFIG.maker.name,
  },
};
